import "./ButtonSpinLoader.scss";
export const ButtonSpinLoader = ({ width, height }) => {
  return (
    <>
      <div
        id="btn-spin-loader"
        style={{ width: width, height: height }}
        className="hide-spin-loader"
      ></div>
    </>
  );
};

import "../../styles/buttonsStyles/buttonsStyles.scss";
import { ButtonSpinLoader } from "./../ButtonSpinLoader/ButtonSpinLoader";

function ButtonGradient({
  style = "",
  text,
  onClick,
  width = "9px",
  height = "9px",
}) {
  const buttonClass = `button__gradient${style ? `--${style}` : ""}`;
  return (
    <button
      style={{
        display: "flex",
        justifyContent: "center",
      }}
      className={buttonClass}
      onClick={onClick}
    >
      <ButtonSpinLoader height={height} width={width} />
      {text}
    </button>
  );
}

export default ButtonGradient;

import { useState } from "react";
import "./FormField.scss";

function FormField({ label, type, id, value, placeholder, onChange, c, r }) {
  const [isFocused, setIsFocused] = useState(false);
  const [hasValue, setHasValue] = useState(false);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = (e) => {
    setIsFocused(false);
    if (e.target.value !== "") {
      setHasValue(true);
    } else {
      setHasValue(false);
    }
  };

  return (
    <div className={`form-group`}>
      {c > 0 && r > 0 ? (
        <textarea
          className="form-field"
          type={type}
          id={id}
          value={value}
          onChange={onChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          placeholder={placeholder}
          cols={c}
          rows={r}
        ></textarea>
      ) : (
        <input
          className="form-field"
          type={type}
          id={id}
          value={value}
          onChange={onChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          placeholder={placeholder}
        />
      )}
    </div>
  );
}

export default FormField;

import "./ComparisonChartAutoShow.scss";
import { useEffect, useState, React } from "react";
import { useSearchParams } from "react-router-dom";
import { ReactComponent as DividerLine } from "../../assets/icons/divider.svg";
import requirementsPositive from "../../assets/icons/mdp-requirements-yes.svg";
import requirementsNegative from "../../assets/icons/mdp-requirements-no.svg";
import recommendedIcon from "../../assets/icons/mdp-recommended.svg";
import LoadingScreen from "../LoadingScreen/LoadingScreen.jsx";
import ButtonGradient from "../ButtonGradient/ButtonGradient.jsx";
import CompatibilitySection from "../CompatibilitySection/CompatibilitySection.jsx";
import CustomizationPopup from "../CustomizationPopup/CustomizationPopup.jsx";
import { trackAutoshowUser } from "../../services/autoshow-service.js";
import { formatToOneDecimal } from "../../utils/formatUtils.js";
import { useAutoshowContext } from "../../contexts/autoshowContext.jsx";
import { useNavigate } from "react-router-dom";
import { ReactComponent as AddFeaturesButton } from "../../assets/icons/add-features.svg";
import { ReactComponent as RestartButton } from "../../assets/icons/restart.svg";
import FeaturePopup from "../FeaturePopup/FeaturePopup.jsx";
import UpdatePopup from "../UpdatePopup/UpdatePopup.jsx";
import { ReactComponent as AddCarSign } from "../../assets/icons/add-cars.svg";
import {ReactComponent as Info} from '../../assets/icons/info.svg';

function CheckIcon({ check }) {
  return (
    <div className="as-requirement-container">
      <img
        className="as-requirement_icon"
        src={check ? requirementsPositive : requirementsNegative}
        alt=""
      />
    </div>
  );
}

export default function ComparisonChartAutoShow() {
  useEffect(() => {
    window.dataLayer.push({
      event: 'comparisonChartView',
      page: {
        url: window.location.href,
        title: document.title
      }
    });
  }, []);
  
  const navigate = useNavigate();
  const [params, setParams] = useSearchParams();
  const [userId, setUserId] = useState(null);
  const [source, setSource] = useState("site");
  const { resetAutoshowContext } = useAutoshowContext();
  const [loading, setLoading] = useState(true);
  const [userSelectedFeatures, setUserSelectedFeatures] = useState([]);
  const [carsToCompareList, setCarsToCompareList] = useState([]);
  const [exclusiveCtaPopup, setExclusiveCtaPopup] = useState(false);
  const [FeatureCtaPopup, setFeatureCtaPopup] = useState(false);
  const [popupOpened, setPopupOpened] = useState(false);
  const [bFlag, setBFlag] = useState(false);
  const [dtgcFlag, setDtgcFlag] = useState(false);
  const [bdtgcFlag, setBdtgcFlag] = useState(false);
  const [customizationPopupToggle, setCustomizationPopupToggle] =
    useState(false);
  const [featurePopupToggle, setFeaturePopupToggle] = useState(false);
  const [addMoreCarsPopup, setAddMoreCarsPopup] = useState(false);
  const [userFlowChoice, setUserFlowChoice] = useState("");

  const bFlagText = "We had to widened our search by adjusting the budget! ";

  const dtgcText =
    "We had to widened our search and found vehicles that are powered similarly!";

  const combinedText =
    "We had to widen our search and found similar vehicle types with the closest price. ";

  const submittedPopupText =
    "Thanks! A car buying bestie from Mae will be in touch! ";

  async function createComparisonChartInfo() {
    const sourceVar = params.get("generated");

    setSource(sourceVar);

    setUserId(params.get("userId"));

    setUserFlowChoice(params.get("flow"));

    let bFlagVar = params.get("bFlag") === "true" ? true : false;
    let dtgcFlagVar = params.get("dtgcFlag") === "true" ? true : false;

    setBFlag(bFlagVar);

    setDtgcFlag(dtgcFlagVar);

    setBdtgcFlag(bFlagVar && dtgcFlagVar);

    const maeTrimIds = [];

    for (let i = 1; i <= 14; i++) {
      const feature = params.get(`feature${i}`);
      const maeTrimId = params.get(`maeTrimId_${i}`);
      if (
        typeof feature === "string" &&
        !userSelectedFeatures.includes(feature)
      )
        userSelectedFeatures.push(feature);
      if (!maeTrimIds.includes(maeTrimId) && i <= 2) maeTrimIds.push(maeTrimId);
    }

    try {
      const response = await fetch("/api/autoshow/getmdpcarsbyid", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify({
          maeTrimIds: maeTrimIds,
          userSelectedFeatures: userSelectedFeatures,
        }),
        credentials: "include",
      });

      if (response.ok) {
        const res = await response.json();
        console.log(res);
        setCarsToCompareList(res.carsInfo);
      } else {
        const res = await response.json();
        console.log("Error : ", res.error);
      }
    } catch (error) {
      console.log("Error : ", error);
    }

    setUserSelectedFeatures(userSelectedFeatures);
    setLoading(false);
  }

  const onRestartButton = () => {
    console.log("restart clicked");
    resetAutoshowContext();
    navigate("/");
    window.location.reload();
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    createComparisonChartInfo();

    return () => {
      setCarsToCompareList([]);
      setUserSelectedFeatures([]);
    };
  }, []);

  useEffect(() => {
    if (source === "mail")
      trackAutoshowUser({ openedChartThroughEmail: true }, userId);
  }, [source]);

  useEffect(() => {
    if (popupOpened) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }
  }, [popupOpened]);

  function exclusiveCtaClicked() {
    window.scrollTo(0, 0);
    trackAutoshowUser({ clickedOnExclusiveDeals: true }, userId);
    setPopupOpened(true);
    setExclusiveCtaPopup(true);
  }

  function featureCtaClicked() {
    window.scrollTo(0, 0);
    trackAutoshowUser({ clickedOnAddingMoreFeatures: true }, userId);
    setPopupOpened(true);
    setFeatureCtaPopup(true);
  }

  function formatPrice(value) {
    return new Intl.NumberFormat("en-US").format(value);
  }

  function formatCapitalisation(str) {
    return str
      .replace(/([A-Z])/g, " $1")
      .trim()
      .replace(/^./, (match) => match.toUpperCase());
  }

  if (loading) {
    return <LoadingScreen />;
  }

  return (
    <>
      {exclusiveCtaPopup ? (
        <CustomizationPopup
          togglePopup={setExclusiveCtaPopup}
          userId={userId}
          popupOpenedfn={setPopupOpened}
          submittedPopup={setCustomizationPopupToggle}
        />
      ) : null}
      {FeatureCtaPopup ? (
        <FeaturePopup
          togglePopup={setFeatureCtaPopup}
          userId={userId}
          popupOpenedfn={setPopupOpened}
          submittedPopup={setFeaturePopupToggle}
        />
      ) : null}

      {addMoreCarsPopup ? (
        <FeaturePopup
          togglePopup={setAddMoreCarsPopup}
          userId={userId}
          popupOpenedfn={setPopupOpened}
          submittedPopup={setFeaturePopupToggle}
        />
      ) : null}

      <div className="popup_position">
        {dtgcFlag && bFlag && bdtgcFlag ? (
          <UpdatePopup Popupshow={setBdtgcFlag} main={combinedText} />
        ) : null}
      </div>
      <div className="popup_position">
        {bFlag && !dtgcFlag ? (
          <UpdatePopup Popupshow={setBFlag} main={bFlagText} />
        ) : null}
      </div>
      <div className="popup_position">
        {dtgcFlag && !bFlag ? (
          <UpdatePopup Popupshow={setDtgcFlag} main={dtgcText} />
        ) : null}
      </div>
      <div className="popup_position">
        {customizationPopupToggle ? (
          <UpdatePopup
            Popupshow={setCustomizationPopupToggle}
            main={submittedPopupText}
          />
        ) : null}
      </div>
      <div className="popup_position">
        {featurePopupToggle ? (
          <UpdatePopup
            Popupshow={setFeaturePopupToggle}
            main={submittedPopupText}
          />
        ) : null}
      </div>
      <div className={`as-comparechart ${popupOpened ? "blured" : ""}`}>
        <div className="as-comparechart__title-container">
          <h3 className="as-comparechart__title">Compare Chart</h3>

          <div className="as-comparechart__restart" onClick={onRestartButton}>
            <span className="as-comparechart__restart--button">
              <RestartButton />
            </span>
            <p className="as-comparechart__restart--text">Restart</p>
          </div>
        </div>
        <div className="as-comparechart__offer-container">
          <p className="as-comparechart__offer-container--text">
            Get Exclusive deals and offers on these vehicles from Mae's
            hand-picked and trusted dealers near you.
          </p>
          <div
            className="as-comparechart__offer-container--button"
            onClick={exclusiveCtaClicked}
          >
            <ButtonGradient text="Buy with Mae" />
          </div>
        </div>
        <div className="as-comparechart__container">
          {/* Car name */}
          <div className="chart-container">
            <h3 className="chart-container__name-text"></h3>
            {carsToCompareList.map((vehicle, index) => (
              <div className="chart-container__container" key={index}>
                <h3 key={index} className="chart-container__name-text">
                  {`${vehicle.vehicleInfo.make} ${vehicle.vehicleInfo.model}`}
                </h3>
              </div>
            ))}
          </div>

          {/* Image section of the car */}
          <div className="chart-container">
            <p className="chart-container__add_car_sign">
              <AddCarSign
                onClick={() => {
                  trackAutoshowUser({ clickedOnAddMoreCars: true }, userId);
                  setAddMoreCarsPopup(true);
                  setPopupOpened(true);
                }}
              />
            </p>
            {carsToCompareList.map((vehicle, index) => (
              <div className="chart-container__container" key={index}>
                <div className="img-block">
                  <img
                    className="img-block--img"
                    src={vehicle.vehicleInfo.amazonImage}
                    alt={vehicle.calculatedMDPFeatures.maeTrimTitle}
                  />
                </div>
              </div>
            ))}
          </div>

            {/* Recommended Trim for the car */}
            <div className="chart-container">
            <div className="label-with-info" style={{justifyContent : "flex-start"}}>
              <p className="chart-container__label-text"  onMouseEnter={() => {
                const tooltip = document.querySelector('.tooltip1');
                tooltip.style.visibility = 'visible';
                tooltip.style.opacity = '1';
               }}
               onMouseLeave={() => {
                const tooltip = document.querySelector('.tooltip1');
                tooltip.style.visibility = 'hidden';
                tooltip.style.opacity = '0';
               }} >Recommended Trim <Info className="chart-container__label-text__translate-y"/>
               
              </p>
              
              <div className="tooltip tooltip1">Lowest trim that gives you what you want and the most value</div>
            </div>
            {carsToCompareList.map((vehicle, index) => (
              <div key={index} className="recommended-trim-block">
                <img
                  src={recommendedIcon}
                  alt="Recommended"
                  className="recommended-trim-block--icon"
                />
                <h4 className="recommended-trim-block--text">
                  {vehicle.vehicleInfo.styleDescription}
                </h4>
              </div>
            ))}
          </div>

          {/* Price section of the car */}
          <div className="chart-container">
          <div className="label-with-info" style={{justifyContent : "flex-start"}}>
            <p className="chart-container__label-text" onMouseEnter={() => {
                  const tooltip = document.querySelector('.tooltip6');
                  tooltip.style.visibility = 'visible';
                  tooltip.style.opacity = '1';
                }}
                onMouseLeave={() => {
                  const tooltip = document.querySelector('.tooltip6');
                  tooltip.style.visibility = 'hidden';
                  tooltip.style.opacity = '0';
                }} >Starting MSRP of Recommended Trim
                <Info />
                </p>
                <div className="tooltip tooltip6">This does not include taxes, dealer fees, extra colours, shipping costs etc.</div>
                </div>
            {carsToCompareList.map((vehicle, index) => (
              <div key={index} className="price-block">
                <p className="price-block--currency">$</p>
                {formatPrice(vehicle.vehicleInfo.baseMSRP)}
              </div>
            ))}
            </div>
          

          <div className="as-divider-line">
            <DividerLine />
          </div>

          {/* Compatibility for the cars */}
          <div className="chart-container">
            <div className="label-with-info" style={{justifyContent : "flex-start"}}>
            <p className="chart-container__label-text" onMouseEnter={() => {
                  const tooltip = document.querySelector('.tooltip5');
                  tooltip.style.visibility = 'visible';
                  tooltip.style.opacity = '1';
                }}
                onMouseLeave={() => {
                  const tooltip = document.querySelector('.tooltip5');
                  tooltip.style.visibility = 'hidden';
                  tooltip.style.opacity = '0';
                }} >Compatibility
                <Info />
                </p>
              <div className="tooltip tooltip5">We calculate our compatibility through a combination of vehicle information prioritising price and features cause we want to get you the most value for least amount of money</div>
            </div>
            
            
            {carsToCompareList.map((vehicle, index) => (
              <div className="match-block" key={index}>
                <CompatibilitySection
                  key={index}
                  vehicle={vehicle}
                  matchedFeaturesLength={vehicle.matchedFeatures.length}
                  totalFeaturesLength={vehicle.totalFeatures.length}
                  flow={userFlowChoice}
                />
              </div>
            ))}
          </div>

          <div className="as-divider-line">
            <DividerLine />
          </div>

          {/* Requirement for the car */}
          <div className="chart-container">
            <div className="compare-feature-block">
              <p className="chart-container__label-text-group">Requirements</p>
              <p className="chart-container__label-text">Engine Power Type</p>
              <p className="chart-container__label-text">Drive Type</p>
            </div>
            {carsToCompareList.map((vehicle, index) => (
              <div key={index} className="compare-feature-block">
                <p
                  className="chart-container__label-text-group"
                  style={{ opacity: 0 }}
                >
                  Requirements
                </p>
                <div className="label-with-info">
                  <div className="compare-feature-block__text">
                    {vehicle.calculatedMDPFeatures.engine.map((powerType, i) => (
                      <span key={i}>
                        {powerType === "PHEV" && "Plug-In Hybrid Gasoline"}
                        {powerType === "HEV" && "Hybrid Gasoline"}
                        {powerType !=="PHEV" && powerType !== "HEV" && powerType}{" "}
                        {"Engine"}
                        {i < vehicle.calculatedMDPFeatures.engine.length - 1 && (
                          <span>, </span>
                        )}
                        
                      </span>
                    ))}
                  </div>
                  {/* {vehicle.calculatedMDPFeatures.engine.map((powerType, i) => (
                    (powerType === "HEV" || powerType ==="PHEV") && (<><Info onMouseEnter={() => {
                      const tooltip = document.querySelector(`.tooltip${index+5}`);
                      tooltip.style.visibility = 'visible';
                      tooltip.style.opacity = '1';
                    }}
                    onMouseLeave={() => {
                      const tooltip = document.querySelector(`.tooltip${index+5}`);
                      tooltip.style.visibility = 'hidden';
                      tooltip.style.opacity = '0';
                    }} />
                   <div className={`tooltip tooltip${index+5}`} style={{width : "fit-content"}}>
                        {powerType === "PHEV" && "PHEV : Plug-In Hybrid Gasoline Engine"}
                        {powerType === "HEV" && "HEV : Hybrid Gasoline Engine"}
                  </div></>
                  )))} */}
              </div>
                
                <div className="label-with-info">
                  <div className="compare-feature-block__text" onMouseEnter={() => {
                    const tooltip = document.querySelector(`.tooltip${index+2}`);
                    tooltip.style.visibility = 'visible';
                    tooltip.style.opacity = '1';
                  }}
                  onMouseLeave={() => {
                    const tooltip = document.querySelector(`.tooltip${index+2}`);
                    tooltip.style.visibility = 'hidden';
                    tooltip.style.opacity = '0';
                  }}>
                    {vehicle.vehicleInfo.driveType}  <Info/>
                  </div>
                 
                  <div className={`tooltip tooltip${index+2}`} style={{width : "fit-content"}}>
                    {vehicle.vehicleInfo.driveType === "AWD" ? "All Wheel Drive" : vehicle.vehicleInfo.driveType === "FWD" ? "Forward Wheel Drive" : "4 Wheel Drive"}
                  </div>
               </div>
              </div>
            ))}
          </div>

          <div className="as-divider-line">
            <DividerLine />
          </div>

          {/* Your desired features for the car */}
          <div className="chart-container">
            <div className="compare-feature-block">
              <p className="chart-container__label-text-group">
                Your Desired Features
              </p>
              {carsToCompareList[0]?.totalFeatures.map((feature, index) => (
                <div className="compare-feature-block__inner" key={index}>
                  <p key={index} className="chart-container__label-text">
                    {formatCapitalisation(feature)}
                  </p>
                  {carsToCompareList.map((vehicle, index) => {
                    return vehicle.vehicleInfo.maeFeatures[feature] === true ? (
                      <CheckIcon check={true} key={index} />
                    ) : (
                      <CheckIcon check={false} />
                    );
                  })}
                </div>
              ))}
              <span className="more-features" onClick={featureCtaClicked}>
                <span className="more-features__add">
                  <AddFeaturesButton />
                </span>
                <span className="more-features__text">Add more Features</span>
              </span>
            </div>
          </div>

          <div className="as-divider-line">
            <DividerLine />
          </div>

          {/* Your other features for the car */}
          <div className="chart-container">
            <div className="compare-feature-block">
              <p className="chart-container__label-text-group">
                Other Features
              </p>
              {Object.keys(
                carsToCompareList[0]
                  ? carsToCompareList[0]?.vehicleInfo.maeFeatures
                  : {}
              ).map((feature,index) => (
                <>
                  {!carsToCompareList[0]?.totalFeatures.includes(feature) &&
                    feature !== "_id" && (
                      <div key={index}>
                        <div className="compare-feature-block__inner">
                          <p className="chart-container__label-text">
                            {formatCapitalisation(feature)}
                          </p>
                          {carsToCompareList.map((vehicle) => {
                            return vehicle.vehicleInfo.maeFeatures[feature] ===
                              true ? (
                              <CheckIcon check={true} key={`${vehicle._maeTrimId}${index}`}/>
                            ) : (
                              <CheckIcon check={false} key={`${vehicle._maeTrimId}${index}`}/>
                            );
                          })}
                        </div>
                      </div>
                    )}
                </>
              ))}
            </div>
          </div>

          <div className="as-divider-line">
            <DividerLine />
          </div>

          {/* Lifestyle section of the car */}
          <div className="chart-container">
            <div className="compare-feature-block">
              <p className="chart-container__label-text-group">Lifestyle</p>
              <div className="compare-feature-block__inner">
              <div className="label-with-info" style={{justifyContent : "flex-start"}}>
                <p className="chart-container__label-text" onMouseEnter={() => {
                  const tooltip = document.querySelector('.tooltip4');
                  tooltip.style.visibility = 'visible';
                  tooltip.style.opacity = '1';
                }}
                onMouseLeave={() => {
                  const tooltip = document.querySelector('.tooltip4');
                  tooltip.style.visibility = 'hidden';
                  tooltip.style.opacity = '0';
                }} >Big Families
                <Info />
                </p>
              <div className="tooltip tooltip4">6+ seats</div>
            </div>
                {carsToCompareList.map((vehicle) => (
                  <span className="compare-feature-block__text" key={vehicle._maeTrimId}>
                    {vehicle.calculatedMDPFeatures.seats > 5 ? (
                      <CheckIcon check={true} />
                    ) : (
                      <CheckIcon check={false} />
                    )}
                  </span>
                ))}
              </div>

              <div className="compare-feature-block__inner">
              <div className="label-with-info" style={{justifyContent : "flex-start"}}>
                <p className="chart-container__label-text" onMouseEnter={() => {
                  const tooltip = document.querySelector('.tooltip7');
                  tooltip.style.visibility = 'visible';
                  tooltip.style.opacity = '1';
                }}
                onMouseLeave={() => {
                  const tooltip = document.querySelector('.tooltip7');
                  tooltip.style.visibility = 'hidden';
                  tooltip.style.opacity = '0';
                }} >Driving in Snowy Conditions
                <Info />
                </p>
              <div className="tooltip tooltip7">This vehicle has AWD or 4x4 drive type meaning it has power running to all four wheels which is recommended for people who love their roadtrips to Whistler.</div>
            </div>
                {carsToCompareList.map((vehicle) => (
                  <span className="compare-feature-block__text" key={vehicle._maeTrimId}>
                    {vehicle.vehicleInfo.driveType === "AWD" ||
                    vehicle.vehicleInfo.driveType === "4x4" ? (
                      <CheckIcon check={true} />
                    ) : (
                      <CheckIcon check={false} />
                    )}
                  </span>
                ))}
              </div>
              <div className="compare-feature-block__inner">
                <p className="chart-container__label-text">
                  A to B City Commuting
                </p>
                {carsToCompareList.map((vehicle) => (
                  <span className="compare-feature-block__text" key={vehicle._maeTrimId}>
                    <CheckIcon check={true} />
                  </span>
                ))}
              </div>
              <div className="compare-feature-block__inner">
              <div className="label-with-info" style={{justifyContent : "flex-start"}}>
                <p className="chart-container__label-text" onMouseEnter={() => {
                  const tooltip = document.querySelector('.tooltip8');
                  tooltip.style.visibility = 'visible';
                  tooltip.style.opacity = '1';
                }}
                onMouseLeave={() => {
                  const tooltip = document.querySelector('.tooltip8');
                  tooltip.style.visibility = 'hidden';
                  tooltip.style.opacity = '0';
                }} >Tall Passenger
                <Info />
                </p>
              <div className="tooltip tooltip8">This vehicle has head room and leg room greater than 38 inches.</div>
            </div>
                {carsToCompareList.map((vehicle) => (
                  <span className="compare-feature-block__text" key={vehicle._maeTrimId}>
                    {vehicle.calculatedMDPFeatures?.headRoomFirstRow > 38 &&
                    vehicle.calculatedMDPFeatures?.legRoomFirstRow > 38 ? (
                      <CheckIcon check={true} />
                    ) : (
                      <CheckIcon check={false} />
                    )}
                  </span>
                ))}
              </div>
              <div className="compare-feature-block__inner">
                <p className="chart-container__label-text">Eco Conscious</p>
                {carsToCompareList.map((vehicle) => (
                  <span className="compare-feature-block__text" key={vehicle._maeTrimId}>
                    {vehicle.calculatedMDPFeatures.engine.includes(
                      "Gasoline"
                    ) ||
                    vehicle.calculatedMDPFeatures.engine.includes("Diesel") ? (
                      <CheckIcon check={false} />
                    ) : (
                      <CheckIcon check={true} />
                    )}
                  </span>
                ))}
              </div>
              <div className="compare-feature-block__inner">
                <p className="chart-container__label-text">Large Trunk Needs</p>
                {carsToCompareList.map((vehicle) => (
                  <span className="compare-feature-block__text" key={vehicle._maeTrimId}>
                    {vehicle.calculatedMDPFeatures.cargo > 20 ||
                    vehicle.vehicleInfo.maeSegment === "SUV" ||
                    vehicle.vehicleInfo.maeCargoCategory === "L" ? (
                      <CheckIcon check={true} />
                    ) : (
                      <CheckIcon check={false} />
                    )}
                  </span>
                ))}
              </div>
            </div>
          </div>
      

            <div className="as-divider-line">
            <DividerLine />
          </div>

          {/* Dimensions for the car */}
          <div className="chart-container">
            <div className="compare-feature-block">
              <p className="chart-container__label-text-group">Dimensions</p>
              <div className="compare-feature-block__inner">
                <p className="chart-container__label-text">
                  Trunk Cargo Volume
                </p>
                {carsToCompareList.map((vehicle) => (
                  <span className="compare-feature-block__text" key={vehicle._maeTrimId}>
                    {formatToOneDecimal(vehicle.calculatedMDPFeatures.cargo) +
                      (vehicle.calculatedMDPFeatures.cargo ? " cu ft." : "")}
                  </span>
                ))}
              </div>
              <div className="compare-feature-block__inner">
                <p className="chart-container__label-text">1st Row Head Room</p>
                {carsToCompareList.map((vehicle) => (
                  <span className="compare-feature-block__text" key={vehicle._maeTrimId}>
                    {vehicle.calculatedMDPFeatures.headRoomFirstRow +
                      (vehicle.calculatedMDPFeatures.headRoomFirstRow
                        ? " in"
                        : "")}
                  </span>
                ))}
              </div>
              <div className="compare-feature-block__inner">
                <p className="chart-container__label-text">2nd Row Head Room</p>
                {carsToCompareList.map((vehicle) => (
                  <span className="compare-feature-block__text" key={vehicle._maeTrimId}>
                    {vehicle.calculatedMDPFeatures.headRoomSecondRow +
                      (vehicle.calculatedMDPFeatures.headRoomSecondRow
                        ? " in"
                        : "")}
                  </span>
                ))}
              </div>
              <div className="compare-feature-block__inner">
                <p className="chart-container__label-text">1st Row Leg Room</p>
                {carsToCompareList.map((vehicle) => (
                  <span className="compare-feature-block__text" key={vehicle._maeTrimId}>
                    {vehicle.calculatedMDPFeatures.legRoomFirstRow +
                      (vehicle.calculatedMDPFeatures.legRoomFirstRow
                        ? " in"
                        : "")}
                  </span>
                ))}
              </div>
              <div className="compare-feature-block__inner">
                <p className="chart-container__label-text">2nd Row Leg Room</p>
                {carsToCompareList.map((vehicle) => (
                  <span className="compare-feature-block__text" key={vehicle._maeTrimId}>
                    {vehicle.calculatedMDPFeatures.legRoomSecondRow +
                      (vehicle.calculatedMDPFeatures.legRoomSecondRow
                        ? " in"
                        : "")}
                  </span>
                ))}
              </div>
            </div>
          </div>
          
          <div className="as-comparechart__offer-container">
            <p className="as-comparechart__offer-container--text">
              Get Exclusive deals and offers on these vehicles from Mae's
              hand-picked and trusted dealers near you.
            </p>
            <div
              className="as-comparechart__offer-container--button"
              onClick={exclusiveCtaClicked}
            >
              <ButtonGradient text="Buy with Mae" />
            </div>
          </div>
      
        </div>
      </div>
    </>
  );
}

import "./QuizOptionCard.scss";
import { useEffect, useState } from "react";

function QuizOptionCard({
  text,
  greyicon,
  subtext,
  selectOption,
  unselectOption,
  selected,
  className = "card__unselected",
  index,
}) {
  const [isSelected, setIsSelected] = useState(selected);

  const handleSelect = () => {
    if (!isSelected) {
      selectOption(text, index);
    } else {
      unselectOption(text, index);
    }
    setIsSelected(!isSelected);
  };

  useEffect(() => {
    setIsSelected(selected);
  }, [selected]);

  // Conditional import for the image to avoid breaking if greyicon is not provided
  const iconSrc = greyicon ? require(`../../assets/icons/${greyicon}`) : "";

  return (
    <div
      className={`card ${className === "big-image" ? "card__wider" : ""} ${
        isSelected ? "card__selected" : "card__unselected"
      } ${subtext === "" ? "" : "card__long"} ${className}`}
      onClick={handleSelect}
    >
      <div
        className={`card__images ${
          className === "feature-card" ? "feature-card__images" : ""
        }`}
      >
        <img
          className={`card__images--icon ${
            className === "feature-card"
              ? "feature-card__images--icon"
              : className === "big-image"
                ? "card__images--big-image"
                : ""
          }`}
          src={iconSrc}
          alt=""
        ></img>
      </div>
      <div
        className={`card--text ${
          className === "feature-card" ? "feature-card--text" : ""
        }`}
      >
        {text}
      </div>
      <div className="card--subtext">{subtext}</div>
    </div>
  );
}

export default QuizOptionCard;

import DashboardHome from "../../autoshow-components/DashboardHome/DashboardHome";

function Main() {
  return (
    <>
      <DashboardHome />
    </>
  );
}

export default Main;

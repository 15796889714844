export const generateAutoshowUserMatches = async (
  quizSelections,
  email,
  flowChoice
) => {
  try {
    const response = await fetch("/api/autoshow/generateautoshowuserprofile", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        selected: quizSelections,
        email: email,
        flowChoice: flowChoice,
      }),
    });

    if (response.ok) {
      let res = await response.json();
      return res;
    } else {
      const errorData = await response.json();
      console.log("Failed:", errorData.error);
      return null;
    }
  } catch (error) {
    console.log("Error:", error.message);
  }
};

export const generateAutoshowUserSelectedProfile = async (
  quizSelections,
  email,
  car1make,
  car2make,
  car1model,
  car2model,
  flowChoice
) => {
  try {
    const response = await fetch(
      "/api/autoshow/generateautoshowuserselectedprofile",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          selected: quizSelections,
          email: email,
          car1make: car1make,
          car2make: car2make,
          car1model: car1model,
          car2model: car2model,
          flowChoice: flowChoice,
        }),
      }
    );

    if (response.ok) {
      let res = await response.json();
      return res;
    } else {
      const errorData = await response.json();
      console.log("Failed:", errorData.error);
      return null;
    }
  } catch (error) {
    console.log("Error:", error.message);
  }
};

export const trackAutoshowUser = async (requestObj, userId) => {
  console.log(userId);
  const response = await fetch(
    `/api/autoshow/trackautoshowuserinfo?userId=${userId}`,
    {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ infoLabels: requestObj }),
    }
  );

  if (response.ok) {
    console.log("User tracked successfully");
  } else {
    const errorResponse = await response.json();
    console.error("Failed to track user", errorResponse?.error);
  }
};

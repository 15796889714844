import "./DashboardHome.scss";
import ButtonGradient from "../ButtonGradient/ButtonGradient";
import dashboardImage from "../../assets/images/laptop-compare.png";
import { Link } from "react-router-dom";
import { ReactComponent as MaeCoreLogoBlack } from "../../assets/logos/MaeCoreLogoBlack.svg";

function DashboardHome() {
  return (
    <div className="dashboard">
      <div className="dashboard__intro">
        <div className="dashboard__intro--logo">
          <MaeCoreLogoBlack />
        </div>
        <div className="dashboard__intro--titles">
          <span className="header1">Compare Cars</span>
          <span className="header2">Your way</span>
        </div>
        <p className="dashboard__intro--description">
          Personalize your own comparison chart to see only the things that
          matter to your decision - without the overwhelming jargon and info.
        </p>
        <div className="dashboard__intro--button">
          <Link to="matchmaker-quiz">
            <ButtonGradient text="Try it Now" />
          </Link>
        </div>
      </div>
      <div className="dashboard__filler">
        <img
          className="dashboard__filler--image"
          src={dashboardImage}
          alt=""
        ></img>
      </div>
    </div>
  );
}

export default DashboardHome;

import Dashboard404 from "../../autoshow-components/Dashboard404/Dashboard404";

function NotFound() {
  return (
    <>
      <Dashboard404 />
    </>
  );
}

export default NotFound;

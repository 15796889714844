import "./FeaturePopup.scss";
import { useEffect, useState } from "react";
import { ReactComponent as MaeBadgeLogoBright } from "../../assets/logos/mae-badge-logo-bright.svg";
import { ReactComponent as Close } from "../../assets/images/X.svg";
import FormField from "../FormField/FormField.jsx";
import ButtonGradient from "../ButtonGradient/ButtonGradient.jsx";
import { trackAutoshowUser } from "../../services/autoshow-service.js";

function FormFieldWithLabel({
  label,
  type,
  id,
  placeholder,
  value,
  onChange,
  c,
  r,
}) {
  return (
    <>
      <div className="form_field_with_labels">
        <span className="form_field_with_labels__text">{label}</span>
        <FormField
          label={label}
          type={type}
          id={id}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          c={c}
          r={r}
        />
      </div>
    </>
  );
}

export default function FeaturePopup({
  togglePopup,
  userId,
  popupOpenedfn,
  submittedPopup,
}) {
  const [featureText, setFeatureText] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="customization_popup_container">
        <div className="badge_logo_bright">
          <MaeBadgeLogoBright />
        </div>
        <div
          className="close_popup_container"
          onClick={() => {
            togglePopup(false);
            popupOpenedfn(false);
          }}
        >
          <Close />
        </div>
        <div className="popup_content">
          <div className="popup_header">
            <p className="popup_header--maintext">More Matches, Deeper Customizations</p>
            <p className="popup_header--subtext">
            Tell us what you want to see in your ideal comparison chart that will help you make a truly informed decision. 
            </p>
          </div>

          <div className="combine-fields">
            <FormFieldWithLabel
              label="Customizations"
              type="textarea"
              id="customizations"
              placeholder="Eg. I need to tow a trailer, fit 3 car seats in 2nd row, we like to camp in our car etc."
              value={featureText}
              onChange={(e) => {
                setFeatureText(e.target.value);
              }}
              c={1}
              r={3}
            />
          </div>
          <div className="button_container">
            <ButtonGradient
              text="Submit"
              onClick={() => {
                trackAutoshowUser({ customizations: featureText }, userId);
                popupOpenedfn(false);
                togglePopup(false);
                submittedPopup(true);
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
}

import "./App.css";
import { Routes, Route } from "react-router-dom";

/* Importing pages for the autoshow product*/
import Main from "./autoshow-pages/Main/Main";
import NotFound from "./autoshow-pages/NotFound/NotFound";
import Quiz from "./autoshow-pages/Quiz/Quiz";

/* Importing Components for the Autoshow Pages */
import LoadingScreen from "./autoshow-components/LoadingScreen/LoadingScreen";
import ComparisonChartAutoShow from "./autoshow-components/ComparisonChartAutoShow/ComparisonChartAutoShow";
import { useAutoshowContext } from "./contexts/autoshowContext";
import TagManager from 'react-gtm-module'
import { useEffect } from "react";


function App() {
  const { isAppLoading } = useAutoshowContext();

  useEffect(() => {
    const tagManagerArgs = {
      gtmId: 'G-XNWBLT2V3YP'
    }
    TagManager.initialize(tagManagerArgs)
    
    window.dataLayer.push({
      event: 'comparisonChartView',
      page: {
        url: window.location.href,
        title: document.title
      }
    });
  }, []);

  if (isAppLoading) {
    return <LoadingScreen />;
  }

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/matchmaker-quiz" element={<Quiz />} />
        <Route path="/compare/autoshow" element={<ComparisonChartAutoShow />} />
        <Route path="/404" element={<NotFound />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
}

export default App;

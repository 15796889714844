import { createContext, useContext, useState } from "react";

const AutoshowContext = createContext();

export const AutoshowProvider = ({ children }) => {
  const [featuresSelected, setFeaturesSelected] = useState([]);
  const [isAppLoading, setIsAppLoading] = useState(false);
  const [flowChoice, setFlowChoice] = useState(null);

  const resetAutoshowContext = () => {
    setFlowChoice(null);
    setFeaturesSelected([]);
    localStorage.removeItem("userId");
    localStorage.removeItem("car1Make");
    localStorage.removeItem("car2Make");
    localStorage.removeItem("car1Model");
    localStorage.removeItem("car2Model");
  };

  return (
    <AutoshowContext.Provider
      value={{
        isAppLoading,
        featuresSelected,
        flowChoice,
        setIsAppLoading,
        resetAutoshowContext,
        setFeaturesSelected,
        setFlowChoice,
      }}
    >
      {children}
    </AutoshowContext.Provider>
  );
};

export const useAutoshowContext = () => {
  return useContext(AutoshowContext);
};

import "./DashboardQuiz.scss";
import { useEffect, useState, useRef } from "react";
import { useNavigate, createSearchParams } from "react-router-dom";
import {
  generateAutoshowUserMatches,
  generateAutoshowUserSelectedProfile,
} from "../../services/autoshow-service";
import { useAutoshowContext } from "../../contexts/autoshowContext";
import { questions } from "../../data/quiz";

/* Components needed for the Quiz */
import QuizProgressBar from "../QuizProgressBar/QuizProgressBar";
import QuizOptionCard from "../QuizOptionCard/QuizOptionCard";
import QuizEmailInput from "../QuizEmailInput/QuizEmailInput";
import SelectCars from "../SelectCars/SelectCars";
import ButtonPlain from "../ButtonPlain/ButtonPlain";
import LoadingScreen from "../LoadingScreen/LoadingScreen";

/* SVG needed in the dasboard quiz */
import { ReactComponent as BadgeLogo } from "../../assets/logos/mae-badge-logo.svg";

function DashboardQuiz() {
  const { featuresSelected, setFeaturesSelected } = useAutoshowContext();
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [quizQuestions, setquizQuestions] = useState(
    JSON.parse(JSON.stringify(questions))
  );
  const quizComponentRef = useRef();
  const [car1MakeAndModel, setCar1MakeAndModel] = useState([]);
  const [car2MakeAndModel, setCar2MakeAndModel] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [validEmail, setValidEmail] = useState(false);
  const navigate = useNavigate();
  const [count, setCount] = useState(5);
  const [flowChoice, setUserFlowChoice] = useState("");
  const [questionIndices, setQuestionIndices] = useState([0]);
  const [currentQuestionCount, setCurrentQuestionCount] = useState(0);
  const ikQuestionsIndices = [0, 1, 6, 7];
  const idkQuestionsIndices = [0, 2, 3, 4, 5, 6, 7];

  const countSelected = () => {
    var count = 0;
    quizQuestions[currentQuestionIndex].options.forEach((option) => {
      if (option.selected) {
        count += 1;
      }
    });
    return count;
  };

  const selectOption = (option, index) => {
    const stateCopy = [...quizQuestions];
    const selectedOptions = stateCopy[currentQuestionIndex].currSelected;
    const maxOptionLen = stateCopy[currentQuestionIndex].selectionRange[1];
    let selectedOptionsIndex = selectedOptions.indexOf(index);
    if (selectedOptions.length >= maxOptionLen) {
      const removedOptionInfo = selectedOptions.shift();
      stateCopy[currentQuestionIndex].options[removedOptionInfo].selected =
        false;
    }
    if (selectedOptionsIndex === -1 && selectedOptions.length < maxOptionLen) {
      selectedOptions.push(index);
      stateCopy[currentQuestionIndex].options[index].selected = true;
    }
    setquizQuestions(stateCopy);

    if (quizQuestions[currentQuestionIndex].shortDescription === "features"){
      console.log(quizQuestions[currentQuestionIndex])
    } 

    if (quizQuestions[currentQuestionIndex].shortDescription === "ikidk") {
      if (quizQuestions[currentQuestionIndex].options[0].selected) {
        setUserFlowChoice("ik");
        setCount(4);
        setQuestionIndices(ikQuestionsIndices);
        console.log("IKIDK SET.");
      } else if (quizQuestions[currentQuestionIndex].options[1].selected) {
        setUserFlowChoice("idk");
        setCount(7);
        setQuestionIndices(idkQuestionsIndices);
        console.log("IKIDK SET.");
      } else {
        console.log("ERROR: IKIDK NOT SET!!!");
      }
    }
  };

  const unselectOption = (option, index) => {
    const stateCopy = [...quizQuestions];
    const selectedOptions = stateCopy[currentQuestionIndex].currSelected;
    let selectedOptionsIndex = selectedOptions.indexOf(index);
    if (selectedOptionsIndex !== -1) {
      stateCopy[currentQuestionIndex].options[index].selected = false;
      stateCopy[currentQuestionIndex].currSelected.splice(
        selectedOptionsIndex,
        1
      );
    }
    setquizQuestions(stateCopy);
  };

  const nextQuestion = () => {
    console.log("next valid question");
    let tempCount = currentQuestionCount;
    setCurrentQuestionCount(tempCount + 1);
    setCurrentQuestionIndex(questionIndices[tempCount + 1]);
  };

  const prevQuestion = () => {
    if (currentQuestionCount - 1 >= 0) {
      let tempCount = currentQuestionCount;
      setCurrentQuestionCount(tempCount - 1);
      setCurrentQuestionIndex(questionIndices[tempCount - 1]);
    } else {
      navigate("/");
    }
  };

  const submit = async () => {
    setIsLoading(true);

    var quizSelections = [];

    for (let i = 0; i < count; i++) {
      var selectedOptions = [];
      var questionIndex = questionIndices[i];
      var question = quizQuestions[questionIndex];

      if (
        question.text === "Lastly, what features would you like in your car?"
      ) {
        const featureOptions = {};

        question.options.forEach((option) => {
          if (option.field) {
            if (option.selected) {
              featuresSelected.push(option.field);
            }
            featureOptions[option.field] = option.selected;
          }
        });

        setFeaturesSelected(featuresSelected);
        quizSelections.push({
          question: question.text,
          selectedOptions: [featureOptions],
        });
      } else {
        question.options.forEach((option) => {
          if (option.selected) {
            selectedOptions.push(option.text);
          }
        });
        quizSelections.push({
          question: question.text,
          selectedOptions: selectedOptions,
        });
      }
    }

    let profile_response = null;

    if (flowChoice === "idk") {
      profile_response = await generateAutoshowUserMatches(
        quizSelections,
        validEmail,
        flowChoice
      );
    } else {
      profile_response = await generateAutoshowUserSelectedProfile(
        quizSelections,
        validEmail,
        car1MakeAndModel[0],
        car2MakeAndModel[0],
        car1MakeAndModel[1],
        car2MakeAndModel[1],
        flowChoice
      );
    }

    if (profile_response != null) {
      console.log(profile_response, "Profile Response");
      if (profile_response.matchedCarIds.length <= 1) {
        navigate("/");
      }

      const maeTrimsIds = {};

      const features = {};
      for (let i = 1; i <= 2; i++) {
        maeTrimsIds[`maeTrimId_${i}`] = profile_response.matchedCarIds[i - 1];
      }

      for (let i = 1; i <= featuresSelected.length; i++) {
        features[`feature${i}`] = featuresSelected[i - 1];
      }

      const idParam = { userId: profile_response?.userId };

      const flagsParam = {
        bFlag: profile_response?.budgetChangedFlag ? "true" : "false",
        dtgcFlag: profile_response?.driveTypeOrGasChangeFlag ? "true" : "false",
      };

      const flowParam = { flow: flowChoice };

      const sourceParam = { generated: "site" };

      const paramsComparison = {
        ...idParam,
        ...maeTrimsIds,
        ...features,
        ...sourceParam,
        ...flagsParam,
        ...flowParam,
      };

      navigate({
        pathname: "/compare/autoshow",
        search: `${createSearchParams(paramsComparison).toString()}`,
      });
    } else {
      navigate("/404");
    }
  };

  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    quizComponentRef.current.scrollTop = 0;
  }, [currentQuestionIndex]);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  if (isLoading) {
    return <LoadingScreen />;
  }

  return (
    <div className="quiz" ref={quizComponentRef}>
      <div className="quiz__filler">
        <div className="quiz__intro">
          <div className="quiz__intro--titles">
            <h2 className="header">
              {quizQuestions[currentQuestionIndex].header}
            </h2>
            <h1 className="subheader">
              {quizQuestions[currentQuestionIndex].text}
            </h1>
            <h3 className="subtext">
              {quizQuestions[currentQuestionIndex].subtext}
            </h3>
            <QuizProgressBar count={count} active={currentQuestionCount} />
          </div>
          <div className="badge__logo">
            <BadgeLogo />
          </div>
        </div>
        <div
          className={
            quizQuestions[currentQuestionIndex].shortDescription === "ikidk"
              ? "quiz__options_two_cards"
              : "quiz__options_four_cards"
          }
        >
          {quizQuestions[currentQuestionIndex].shortDescription === "email" ? (
            <QuizEmailInput setValidEmail={setValidEmail} />
          ) : quizQuestions[currentQuestionIndex].shortDescription ===
            "pick-models" ? (
            <SelectCars
              quizQuestions={quizQuestions}
              setquizQuestions={setquizQuestions}
              setCar1MakeAndModel={setCar1MakeAndModel}
              setCar2MakeAndModel={setCar2MakeAndModel}
            />
          ) : (
            quizQuestions[currentQuestionIndex].options.map(
              function (option, index) {
                if (
                  quizQuestions[currentQuestionIndex].shortDescription ===
                    "Vehicle Engine" &&
                  option.text == "Full electric" &&
                  quizQuestions[2].options[4].selected
                ) {
                  return null;
                }
                return (
                  <QuizOptionCard
                    key={currentQuestionIndex * 100 + index}
                    index={index}
                    text={option.text}
                    subtext={option.subtext}
                    greyicon={option.greyicon}
                    selectOption={selectOption}
                    unselectOption={unselectOption}
                    selected={option.selected}
                    className={
                      quizQuestions[currentQuestionIndex].shortDescription ===
                      "features"
                        ? "feature-card"
                        : quizQuestions[currentQuestionIndex]
                              .shortDescription === "ikidk"
                          ? "big-image"
                          : ""
                    }
                  />
                );
              }
            )
          )}
        </div>
        <div className="quiz__buttons">
          <div>
            <ButtonPlain
              border="none"
              padding="1.25rem 2.5rem"
              text="Back"
              onClick={prevQuestion}
            />
          </div>
          <div>
            {currentQuestionCount + 1 < count ? (
              <ButtonPlain
                color={
                  countSelected() >=
                  quizQuestions[currentQuestionIndex].selectionRange[0]
                    ? "#FFE6A9"
                    : "#EEEEED"
                }
                border="none"
                padding="1.25rem 4.125rem"
                text="Continue"
                onClick={
                  countSelected() >=
                  quizQuestions[currentQuestionIndex].selectionRange[0]
                    ? nextQuestion
                    : null
                }
              />
            ) : (
              <ButtonPlain
                color={validEmail !== false ? "#FFE6A9" : "#EEEEED"}
                border="none"
                padding="1.25rem 4.125rem"
                text="Continue"
                onClick={validEmail !== false ? submit : null}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default DashboardQuiz;

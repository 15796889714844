import "./Dashboard404.scss";
import ButtonPlain from "../ButtonPlain/ButtonPlain";
import { Link } from "react-router-dom";
import notFoundImg from "../../assets/images/NotFound.svg";

function Dashboard404() {
  return (
    <div className="page404">
      <h2 className="page404__header">Oops!</h2>
      <h1 className="page404__subheader">Looks like you went off roading</h1>
      <p className="page404__description">
        Let’s get you back on the right path! Please return to the homepage to
        find what you’re looking for or enjoy a different link from our sidebar.
      </p>
      <Link to="/">
        <ButtonPlain className="button__plain--narrow" text="Return Home" />
      </Link>
      <p className="page404__support">
        Need some support? Email our team at{" "}
        <a href="mailto:mae@makingautoeasy.com">mae@makingautoeasy.com</a>.
      </p>
      <img className="page404__filler" src={notFoundImg} alt=""></img>
    </div>
  );
}

export default Dashboard404;

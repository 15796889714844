export const questions = [
  {
    flow: "show",
    header: "Create Your Profile",
    text: "Let's start - where are you in your car buying journey?",
    shortDescription: "ikidk",
    currSelected: [],
    selectionRange: [1, 1],
    options: [
      {
        text: "I know what I want",
        subtext: "Pick the vehicles you want to compare by name",
        greyicon: "ik.svg",
        selected: false,
      },
      {
        text: "I don’t know just yet",
        subtext: "Answer a few questions to discover your options",
        greyicon: "idk.svg",
        selected: false,
      },
    ],
  },
  {
    flow: "ik",
    header: "Pick Models",
    text: "Pick 2 Vehicles",
    shortDescription: "pick-models",
    currSelected: [],
    selectionRange: [1, 1],
    options: [
      {
        text: "PICK",
        subtext: "I basically know what I want, I just need help finding it",
        greyicon: "quiz-car-grey.svg",
        selected: false,
      },
    ],
  },
  {
    flow: "idk",
    header: "Create Your Profile",
    text: "What type of vehicle are you looking for?",
    subtext: "Pick one, you can always change later",
    shortDescription: "Vehicle Type",
    currSelected: [],
    selectionRange: [1, 1],
    options: [
      {
        text: "Car",
        subtext: "",
        greyicon: "quiz-car-grey.svg",
        selected: false,
      },
      {
        text: "Small SUV",
        subtext: "5 seater with just enough trunk space for everyday needs",
        greyicon: "quiz-suv-grey.svg",
        selected: false,
      },
      {
        text: "Medium SUV",
        subtext: "Slightly larger 5 seater with more trunk space",
        greyicon: "quiz-suv-grey.svg",
        selected: false,
      },
      {
        text: "Large SUV",
        subtext: "The roomiest 2 row or 3 row SUVs for larger families",
        greyicon: "quiz-suv-grey.svg",
        selected: false,
      },
      {
        text: "Minivan",
        subtext: "",
        greyicon: "quiz-minivan-grey.svg",
        selected: false,
      },
    ],
  },
  {
    flow: "idk",
    header: "Create Your Profile",
    text: "What are your driving conditions?",
    shortDescription: "Driving Conditions",
    currSelected: [],
    selectionRange: [1, 1],
    options: [
      {
        text: "Fair Weather Zoomer",
        subtext: "Eg. Drives to work from Vancouver to Burnaby",
        greyicon: "quiz-sunny-grey.svg",
        selected: false,
      },
      {
        text: "It Snows Frequently",
        subtext: "Eg. Weekend road trips to Whistler",
        greyicon: "quiz-snowman-grey.svg",
        selected: false,
      },
      {
        text: "Off-roading is my jam",
        subtext: "Find me on back country logging roads",
        greyicon: "quiz-offroading-grey.svg",
        selected: false,
      },
    ],
  },
  {
    flow: "idk",
    header: "Create Your Profile",
    text: "How do you want your vehicle powered?",
    subtext: "Select all your preference(s)",
    shortDescription: "Vehicle Engine",
    currSelected: [],
    selectionRange: [1, 5],
    options: [
      {
        text: "Gasoline",
        subtext: "",
        greyicon: "quiz-gas-grey.svg",
        selected: false,
      },
      {
        text: "Hybrid",
        subtext: "",
        greyicon: "quiz-hybrid-grey.svg",
        selected: false,
      },
      {
        text: "Plug-in Hybrid",
        subtext: "",
        greyicon: "quiz-plugin-grey.svg",
        selected: false,
      },
      {
        text: "Full electric",
        subtext: "",
        greyicon: "quiz-ev-grey.svg",
        selected: false,
      },
      // {
      //   text: "Diesel",
      //   subtext: "",
      //   greyicon: "quiz-diesel-grey.svg",
      //   selected: false,
      // },
    ],
  },
  {
    flow: "idk",
    header: "Create Your Profile",
    text: "What’s your ideal budget?",
    type: "budget",
    shortDescription: "Budget",
    currSelected: [],
    selectionRange: [1, 1],
    options: [
      // {
      //   text: "$1,000 - $10,000",
      //   subtext: "",
      //   selected: false,
      //   value: 10000,
      // },
      {
        text: "$10,000 - $20,000",
        subtext: "",
        selected: false,
        value: 20000,
      },
      {
        text: "$20,000 - $30,000",
        subtext: "",
        selected: false,
        value: 30000,
      },
      {
        text: "$30,000 - $40,000",
        subtext: "",
        selected: false,
        value: 40000,
      },
      {
        text: "$40,000 - $50,000",
        subtext: "",
        selected: false,
        value: 50000,
      },
      {
        text: "$50,000 - $60,000",
        subtext: "",
        selected: false,
        value: 60000,
      },
      {
        text: "$60,000 - $70,000",
        subtext: "",
        selected: false,
        value: 70000,
      },
      {
        text: "$70,000 - $80,000",
        subtext: "",
        selected: false,
        value: 80000,
      },
      {
        text: "$80,000+",
        subtext: "",
        selected: false,
        value: 100000,
      },
    ],
  },
  {
    flow: "show",
    header: "Feature Request",
    text: "Lastly, what features would you like in your car?",
    subtext:
      "Please select at least 3 features. Don’t worry, these are not deal-breakers for what we recommend you!",
    shortDescription: "features",
    currSelected: [],
    selectionRange: [3, 14],
    options: [
      {
        text: "Heated Seats",
        subtext: "",
        greyicon: "feature-heated-seats-grey.svg",
        selected: false,
        field: "heatedSeats",
      },
      {
        text: "Cooling Seats",
        subtext: "",
        greyicon: "feature-cooling-seats-grey.svg",
        selected: false,
        field: "coolingSeats",
      },
      {
        text: "Heated Steering Wheel",
        subtext: "",
        greyicon: "feature-heated-steering-wheel-grey.svg",
        selected: false,
        field: "heatedSteeringWheel",
      },
      {
        text: "Push Start and Keyless Entry",
        subtext: "",
        greyicon: "feature-push-start-grey.svg",
        selected: false,
        field: "pushStartAndKeylessEntry",
      },
      {
        text: "Leather/Leatherette Seats",
        subtext: "",
        greyicon: "feature-leather-cloth-seats-grey.svg",
        selected: false,
        field: "leatherSeats",
      },
      {
        text: "Seat Memory",
        subtext: "",
        greyicon: "feature-seat-memory-grey.svg",
        selected: false,
        field: "seatMemory",
      },
      {
        text: "Power Seats",
        subtext: "",
        greyicon: "feature-power-seats-grey.svg",
        selected: false,
        field: "powerSeats",
      },
      {
        text: "Apple CarPlay/Android Auto",
        subtext: "",
        greyicon: "feature-carplay-grey.svg",
        selected: false,
        field: "appleCarplay",
      },
      {
        text: "Sun Roof",
        subtext: "",
        greyicon: "feature-sunroof-grey.svg",
        selected: false,
        field: "sunroof",
      },
      {
        text: "Remote Start",
        subtext: "",
        greyicon: "feature-auto-start-grey.svg",
        selected: false,
        field: "remoteStart",
      },
      {
        text: "Power Liftgate",
        subtext: "",
        greyicon: "feature-power-liftgate-grey.svg",
        selected: false,
        field: "powerLiftgate",
      },
      // {
      //   text: "Power Driver Seat",
      //   subtext: "",
      //   greyicon: "feature-power-seats-grey.svg",
      //   selected: false,
      //   field: "powerDriverSeat",
      // },
      // {
      //   text: "Driver Seat Memory",
      //   subtext: "",
      //   greyicon: "feature-seat-memory-grey.svg",
      //   selected: false,
      //   field: "driverSeatMemory",
      // },
    ],
  },
  {
    flow: "show",
    header: "Get Your Copy",
    text: "Please enter your email",
    subtext:
      "We’ll send you a copy of  your chart so you can always come back to it!",
    shortDescription: "email",
    currSelected: [],
    selectionRange: [0],
    options: [],
  },
];

import "./UpdatePopup.scss";
import { useEffect } from "react";
import X from "../../assets/images/X.svg";

function UpdatePopup({ Popupshow, main }) {
  useEffect(() => {
    window.scrollTo(0, 0);
    setTimeout(() => {
      Popupshow(false);
    }, 5000);
  });
  return (
    <div className="updatePopupLayout">
      <div class="loading-bar"></div>
      <img
        src={X}
        alt="exit"
        className="updatePopupLayout__exit"
        onClick={() => {
          Popupshow(false);
        }}
      />
      <p className="updatePopupLayout__maintext">{main}</p>
    </div>
  );
}

export default UpdatePopup;

import React, { useState, useEffect } from "react";
import "./QuizEmailInput.scss";

const QuizEmailInput = ({ setValidEmail }) => {
  const [email, setEmail] = useState("");
  const [isValid, setIsValid] = useState(true);

  const handleChange = (e) => {
    const enteredEmail = e.target.value;
    setEmail(enteredEmail);

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setIsValid(emailRegex.test(enteredEmail));
  };

  useEffect(() => {
    if (isValid && email !== "") {
      console.log("Setting valid email:", email);
      setValidEmail(email);
    }
  }, [isValid, email]);

  return (
    <div className={`email-input-container ${isValid ? "" : "invalid"}`}>
      <input
        type="email"
        id="email"
        placeholder="Enter your email"
        value={email}
        onChange={handleChange}
      />
      {!isValid && (
        <p className="error-message">Please enter a valid email address</p>
      )}

      <p className="disclaimer">
        P.S. Only Mae will contact you, zero third parties! As a new startup, we’re not a fan of
        spam emails, instead you can expect personal updates when we have new
        awesome tools for you to use or special promos. You won’t get any emails
        from our dealer partners unless you sign up for them later. Plus you can
        break up with us and unsubscribe any time.
      </p>
    </div>
  );
};

export default QuizEmailInput;

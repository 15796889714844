import "./CompatibilitySection.scss";
import EllipsePercentMatch from "../EllipsePercentMatch/EllipsePercentMatch";

export default function CompatibilitySection({
  vehicle,
  totalFeaturesLength = vehicle?.totalFeatures.length,
  matchedFeaturesLength = vehicle?.matchedFeatures.length,
  flow,
}) {
  return (
    <>
      <div className="liked-card-match">
        {" "}
        <div className="liked-card-match__circle">
          <EllipsePercentMatch percentage={vehicle?.compatibilityPercentage} />
          <div className="liked-card-match-stamp">
            <p className="liked-card-match-stamp__number">
              {vehicle?.compatibilityPercentage}%
            </p>
          </div>
        </div>
        <div className="match__text">
          <div className="match__text--row">
            {flow !== "ik" ? (
              <>
                <p className="text-bold">5/5</p>{" "}
                <p className="text-normal">requirements</p>
              </>
            ) : null}
          </div>
        </div>
        <div className="match__text">
          <div className="match__text--row">
            <p className="text-bold">
              {" "}
              {matchedFeaturesLength}/{totalFeaturesLength}
            </p>
            <p className="text-normal">features</p>
          </div>
        </div>
      </div>
    </>
  );
}
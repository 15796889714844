import "./SelectCars.scss";
import { makesandmodels } from "../../data/makesandmodels";
import { useState, useEffect } from "react";

export default function SelectCars({
  setquizQuestions,
  quizQuestions,
  setCar1MakeAndModel,
  setCar2MakeAndModel,
}) {
  const [car1Make, setCar1Make] = useState("");
  const [car1Model, setCar1Model] = useState("");
  const [car2Make, setCar2Make] = useState("");
  const [car2Model, setCar2Model] = useState("");
  const [makesandmodelsObj, setMakesAndModelsObj] = useState(makesandmodels);

  useEffect (() => {
    const sortAlphabetically = (arr) => arr.sort((a, b) => a.localeCompare(b));

    const sortedKeys = Object.keys(makesandmodels).sort();

    const sortedMakesAndModels = {};
    sortedKeys.forEach((key) => {
      sortedMakesAndModels[key] = sortAlphabetically(makesandmodels[key]);
    });

    setMakesAndModelsObj(sortedMakesAndModels);
  },[])

  useEffect(() => {
    const stateCopy = [...quizQuestions];
    if (car1Make && car2Make && car1Model && car2Model) {
      stateCopy[1].currSelected = [car1Make, car2Make, car1Model, car2Model];
      stateCopy[1].options[0].selected = true;
    } else {
      stateCopy[1].options[0].selected = false;
    }
    setquizQuestions(stateCopy);
  }, [car1Make, car2Make, car1Model, car2Model]);

  const handleMakeChange = (car, make) => {
    if (car === "car1") {
      setCar1Make(make);
      setCar1Model("");
      setCar1MakeAndModel([make, ""]);
    } else {
      setCar2Make(make);
      setCar2Model("");
      setCar2MakeAndModel([make, ""]);
    }
  };

  const handleModelChange = (car, model) => {
    if (car === "car1") {
      setCar1Model(model);
      setCar1MakeAndModel([car1Make, model]);
    } else {
      setCar2Model(model);
      setCar2MakeAndModel([car2Make, model]);
    }
  };

  return (
    <div className="container">
      <h4>Vehicle 1</h4>
      <div className="dropdown-container">
        <div className="dropdown-group">
          <label htmlFor="carMake">Car Make</label>
          <select
            value={car1Make}
            onChange={(e) => handleMakeChange("car1", e.target.value)}
            className="dropdown"
          >
            <option value="" disabled hidden>
              Select Make
            </option>
            {Object.keys(makesandmodelsObj).map((item, index) => {
              return (<option key={index} value={item}>
                  {item}
                </option>)
             
            })}
          </select>
        </div>
        <div className="dropdown-group">
          <label htmlFor="carMake">Car Model</label>
          <select
            value={car1Model}
            onChange={(e) => handleModelChange("car1", e.target.value)}
            className="dropdown"
          >
            <option value="">Select Model</option>
            {makesandmodelsObj[car1Make] &&
              makesandmodelsObj[car1Make].map((item, index) => {
                return car2Make == car1Make && car2Model == item ? null : 
                (<option key={index} value={item}>
                  {item}
                </option>)
})}
          </select>
        </div>
      </div>
      <h4>Vehicle 2</h4>
      <div className="dropdown-container">
        <div className="dropdown-group">
          <label>Car Make</label>
          <select
            value={car2Make}
            onChange={(e) => handleMakeChange("car2", e.target.value)}
            className="dropdown"
          >
            <option value="">Select Make</option>
            {Object.keys(makesandmodelsObj).map((item, index) => {
              return (
                <option key={index} value={item}>
                  {item}
                </option>
              );
            })}
          </select>
        </div>
        <div className="dropdown-group">
          <label>Car Model</label>
          <select
            value={car2Model}
            onChange={(e) => handleModelChange("car2", e.target.value)}
            className="dropdown"
          >
            <option value="">Select Model</option>
            {makesandmodelsObj[car2Make] &&
              makesandmodelsObj[car2Make].map((item, index) => {
                return car2Make == car1Make && car1Model == item ? null : 
                (<option key={index} value={item}>
                  {item}
                </option>)
            })}
          </select>
        </div>
      </div>
    </div>
  );
}
